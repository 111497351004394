import api from 'config/api'

import Logger from './logger'
import camelize, { decamelize } from './camelize'

const caller = key => (...args) => getResponse(api[key], ...args)

export default {
  get: caller('get'),
  put: caller('put'),
  post: caller('post'),
  delete: caller('delete'),
  patch: caller('patch')
}

export async function getResponse (call, path, data, headers, ...rest) {
  data = decamelize(data)
  const response = await getDefaultResponse(call, path, data, headers, ...rest)
  return camelize(response)
}

export async function getDefaultResponse (apiCall, ...args) {
  let error
  let data

  try {
    const response = await apiCall(...args)

    if (!response.ok) {
      error = getResponseError(response)
      data = response
    } else {
      data = response.data
    }
  } catch (err) {
    Logger.captureException(err)

    if (typeof err === 'string') {
      error = err
    } else if (err && err.name && err.message) {
      error = `${err.name}: ${err.message}`
    }
  }

  // For requests that don't have a response body like `DELETE`
  if (!error && !data) {
    data = true
  }

  return [error, data]
}

export function getResponseError (response) {
  let error

  if (response.data) {
    error = [response.data.errors, response.data.error].find(
      err => typeof err !== 'undefined'
    )
    error = error || response.data
  } else {
    error = response.problem
  }

  return error
}

export function getReadableError (error) {
  let readableError = ''

  if (Array.isArray(error)) {
    error = error[0]
  }

  if (typeof error !== 'string' && error.message) {
    readableError = error.message
  }

  if (!error || !readableError) {
    readableError = 'Oops. Something bad happened! Try again later.'
  }

  return readableError
}
