import React from 'react'

import get from 'lodash/get'
import addMonths from 'date-fns/addMonths'

import { Container, Subscribe } from 'unstated'

import apiConfig from 'config/api'

import api from 'utils/api'
import cache, { clearLocalStorage } from 'utils/cache'
import logger from 'utils/logger'

export const getCurrentUser = () => cache.get('user')

export const isLoggedIn = () => {
  const user = getCurrentUser()

  return !!get(user, 'id') && !!get(user, 'jwtToken')
}

const setUser = (user, expires) => {
  if (user) {
    const { email, id } = user

    const options = { email }

    if (user.firstName) {
      options.FirstName = user.firstName
      options.LastName = user.lastName
    }

    logger.identify(id, options)

    apiConfig.setHeader('AUTH-TOKEN', user.jwtToken)

    cache.set('user', user, expires)
  } else {
    logger.identify(null)
    apiConfig.setHeader('AUTH-TOKEN', '')

    cache.clear()
    clearLocalStorage()
  }

  return user
}

export const handleLogin = async (user, remember) => {
  logger.captureBreadcrumb({
    message: 'Logged in',
    category: 'auth',
    data: {
      userID: get(user, 'id'),
      email: get(user, 'email')
    }
  })

  if (user) {
    const details = await getProfileDetails(user)

    let expires

    if (remember) {
      expires = addMonths(new Date(), 6)
    }

    return setUser({ ...user, ...details }, expires)
  }

  return false
}

export const logout = callback => {
  logger.captureBreadcrumb({
    message: 'Logged out',
    category: 'auth'
  })

  setUser(null)
  callback && callback()
}

export async function getProfileDetails (user) {
  if (!checkAuthToken(user)) return {}

  const [error, data] = await api.get('/account/profile_details')

  if (error) {
    return {}
  }

  return data.user
}

function checkAuthToken (user) {
  // If there is no auth token avoid making API calls

  if (user) {
    apiConfig.setHeader('AUTH-TOKEN', user.jwtToken)
  }

  return !!get(apiConfig, 'headers[AUTH-TOKEN]')
}

export class AuthContainer extends Container {
  constructor (props) {
    super(props)

    this.loadDetails()
  }

  state = {
    user: getCurrentUser(),
    loading: false
  }

  getCurrentUser = () => {
    return this.state.user || getCurrentUser()
  }

  isLoggedIn = () => {
    const user = this.getCurrentUser()
    return !!get(user, 'id') && !!get(user, 'jwtToken')
  }

  handleLogin = async (user, remember) => {
    const userState = await handleLogin(user, remember)

    await this.setState({ user: userState })

    return userState
  }

  loadDetails = async () => {
    const user = this.getCurrentUser()

    if (!user) return

    this.setState({ loading: true })

    const details = await getProfileDetails(user)

    const expires = addMonths(new Date(), 6)

    const userDetails = { ...user, ...details }

    await this.setState({ user: userDetails, loading: false })

    return setUser(userDetails, expires)
  }

  logout = callback => {
    this.setState({ user: null })
    logout(callback)
  }
}

const container = new AuthContainer()

export const AuthProvider = ({ children }) => (
  <Subscribe to={[container]}>{store => children(store)}</Subscribe>
)
