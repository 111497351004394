import get from 'lodash/get'
import root from 'utils/windowOrGlobal'

export const HOME = '/?h=1'
export const BUSINESS = '/business'
export const FRIENDSHIP = '/friendship'
export const ADVENTURE = '/adventure'
export const GET_APP = '/staycirclesapp'
export const GET_ADVENTURE = '/adventuremachineapp'

export const CREATE_ACCOUNT = '/signup'
export const SIGNUP = '/?signup=true'
export const GET_ADVENTURE_MACHINE_POPUP = '?get_adventure_machine'
export const LOGIN = '?login=true'
export const FORGOT_PASSWORD = '?forgot_password=true'
export const LOGOUT = '/logout'

export const APP = '/app'
export const EXPLORE = '/app/explore'
export const BOOKING = '/app/booking'
export const BOOKING_BUSINESS = 'app/booking/business'
export const BOOKING_FRIENDS = 'app/booking/friends'
export const HOSTING = 'app/hosting'
export const HOSTING_BUSINESS = 'app/hosting/business'
export const HOSTING_FRIENDS = 'app/hosting/friendship'
export const ADVENTURE_SETTINGS = 'app/adventure'
export const SETTINGS = '/app/settings'

export const PRIME = '/prime'
export const PRIME_FIRST = '/primefirstclass'
export const EVENTS = '/events'
export const MESSAGES = '/app/messages'
export const MESSAGES_ARCHIVE = '/app/messages/archive'
export const STAYS = '/app/stays'
export const HELP = '/app/help'

export const PRIVACY = '/about/privacy'
export const TERMS = '/about/terms'
export const CONTACT = '/contact'

/* Settings */
export const PROFILE = `${SETTINGS}/profile`
export const PASSWORD = `${SETTINGS}/password`
export const ADDRESS_VISIBILITY = `${SETTINGS}/address`
export const NOTIFICATIONS = `${SETTINGS}/notifications`
export const PAYMENT = `${SETTINGS}/payment`
export const PAYOUT = `${SETTINGS}/payout`
export const TRANSACTIONS = `${SETTINGS}/transactions`
export const MAINTENANCE = `${SETTINGS}/maintenance`
export const PRIME_SUBSCRIPTION = `${SETTINGS}/prime`
export const PERMANENTLY_DELETE_ACCOUNT = `${SETTINGS}/delete-account`

/* Adventure Machine */
export const ADVENTURE_DEALS = `${ADVENTURE_SETTINGS}/deals`

export function preserveQuery (route) {
  let search = get(root, 'location.search', '')

  if (typeof search !== 'string' || !search.startsWith('?')) {
    search = ''
  }

  return `${route}${search}`
}

export function preserveParent (route) {
  const path = get(root, 'location.pathname', '')
  return `${path}${route}`
}
