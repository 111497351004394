import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import config from 'config'

const isDev = config.ENV === 'development'

let isInitialized = false

const initialize = () => {
  if (isDev || !config.SENTRY_KEY || !Sentry) {
    return
  }

  Sentry.init({
    dsn: config.SENTRY_KEY,
    environment: config.ENV
  })

  Sentry.configureScope(scope => {
    scope.setExtra('sessionURL', getSessionURL())
  })

  if (config.LOGROCKET_KEY && LogRocket) {
    LogRocket.init(config.LOGROCKET_KEY, {
      network: {
        requestSanitizer: function (request) {
          if (request.headers['AUTH-TOKEN']) {
            request.headers['AUTH-TOKEN'] = ''
          }

          return request
        }
      }
    })

    setupLogRocketReact(LogRocket)
  }

  isInitialized = true
}

const maybeInitialize = () => {
  if (!isInitialized) {
    initialize()
  }
}

const identify = (id, options = {}) => {
  consoleLogDuringDev(id, options)
  maybeInitialize()

  if (!isInitialized) return

  Sentry.configureScope(scope => {
    scope.setUser({ id, ...options })
  })

  LogRocket.identify(id, options)
}

const captureBreadcrumb = info => {
  consoleLogDuringDev(info)
  maybeInitialize()

  if (!isInitialized) return

  Sentry.addBreadcrumb(info)
  LogRocket.log(info)
}

const captureException = (error, extra) => {
  if (error instanceof Error === false) {
    error = new Error(error)
  }

  consoleLogDuringDev(error, extra)
  maybeInitialize()

  if (!isInitialized) return

  Sentry.captureException(error, extra)
  LogRocket.captureException(error, extra)
}

const captureMessage = (msg, extra) => {
  consoleLogDuringDev(msg, extra)
  maybeInitialize()

  if (!isInitialized) return

  Sentry.captureMessage(msg, extra)
  LogRocket.captureMessage(msg, extra)
}

const configureScope = callback => {
  consoleLogDuringDev(callback)
  maybeInitialize()

  if (!isInitialized) return

  Sentry.configureScope(callback)
}

const getSessionURL = cb => {
  if (!isInitialized) return

  let recordingURL = LogRocket.sessionURL

  if (recordingURL) {
    typeof cb === 'function' && cb(recordingURL)
    return recordingURL
  }

  LogRocket.getSessionURL(sessionURL => {
    recordingURL = sessionURL
    typeof cb === 'function' && cb(recordingURL)
  })

  return recordingURL
}

export default {
  identify,
  captureBreadcrumb,
  captureException,
  captureMessage,
  configureScope,
  getSessionURL
}

function consoleLogDuringDev (...args) {
  if (isDev) {
    console.log(...args)
  }
}
