import Cookies from 'universal-cookie'

import logger from './logger'
import config from 'config'

const cookies = new Cookies()
const secure = config.ENV === 'production'

export default {
  get: getCache,
  set: setCache,
  remove: removeCache,
  clear: clearCache
}

export function getCache (name) {
  try {
    if (name) {
      return cookies.get(name)
    }

    return cookies.getAll()
  } catch (error) {
    logger.captureException(error)
  }
}

export function setCache (name, value, expires) {
  try {
    cookies.set(name, value, { path: '/', expires, secure })
  } catch (error) {
    logger.captureException(error)
  }
}

export function removeCache (name) {
  try {
    cookies.remove(name, { path: '/', secure })
  } catch (error) {
    logger.captureException(error)
  }
}

export function clearCache () {
  try {
    const allCookies = cookies.getAll()
    const keys = Object.keys(allCookies)
    keys.forEach(removeCache)
  } catch (error) {
    logger.captureException(error)
  }
}

export function removeLocalStorage (name) {
  try {
    typeof window !== 'undefined' && window.localStorage.removeItem(name)
  } catch (error) {
    logger.captureException(error)
  }
}

export function clearLocalStorage () {
  try {
    typeof window !== 'undefined' && window.localStorage.clear()
  } catch (error) {
    logger.captureException(error)
  }
}
