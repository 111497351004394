/* Code from 'lodash-humps' */

import {
  isArray,
  isObjectLike,
  isPlainObject,
  map,
  transform,
  set,
  camelCase,
  snakeCase
} from 'lodash'

function createIteratee (converter, self) {
  return (result, value, key) =>
    set(result, converter(key), isObjectLike(value) ? self(value) : value)
}

function createHumps (keyConverter) {
  return function humps (node) {
    if (isArray(node)) return map(node, humps)
    if (isPlainObject(node)) {
      return transform(node, createIteratee(keyConverter, humps))
    }
    return node
  }
}

const humps = createHumps(camelCase)
const snakes = createHumps(snakeCase)

export const decamelize = obj => snakes(obj)

export default (obj, deca) => {
  if (deca) {
    return decamelize(obj)
  }

  return humps(obj)
}
