import { create as createApi } from 'apisauce'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import logger from 'utils/logger'

import config from 'config'
import * as routes from 'config/routes'
import parseParams from 'utils/parse-params'
import cache from 'utils/cache'

import root from 'utils/windowOrGlobal'
import { logout } from 'stores/auth'

export function createBackendApi (baseURL = config.API_URL) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'API-SECRET': config.API_SECRET
  }

  const token = get(cache.get('user'), 'jwtToken')

  if (token) {
    headers['AUTH-TOKEN'] = token
  }

  const api = createApi({
    baseURL,
    headers
  })

  api.addRequestTransform(request => {
    if (!isEmpty(request.params)) {
      request.params = parseParams(request.params)
    }

    // Add LogRocket session URL to request header
    // to facilitate backend debugging
    const sessionURL = logger.getSessionURL()

    if (sessionURL) {
      request.headers['X-LogRocket-URL'] = sessionURL
    }
  })

  api.addAsyncResponseTransform(async response => {
    const status = get(response, 'status')
    if (status === 401) {
      let { location } = root
      const next = encodeURIComponent(`${location.pathname}${location.search}`)
      location = `/${routes.LOGIN}&next=${next}`
      logout(() => {
        setTimeout(() => {
          root.location = location
        }, 1000)
      })
      return null
    }
  })

  return api
}

const defaultBackendApi = createBackendApi()
export default defaultBackendApi

const cloudinaryApi = createApi({
  baseURL:
    'https://api.cloudinary.com/v1_1/' +
    config.CLOUDINARY_CLOUDNAME +
    '/image/upload'
})

export const cloudinaryUpload = cloudinaryApi
