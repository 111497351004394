import isNil from 'lodash/isNil'
import keys from 'lodash/keys'
import isPlainObject from 'lodash/isPlainObject'

// Properly format params for API
// e.g. {page: {number: 1}} becomes {page[number]: 1}
const parseParams = params => {
  const parsedParams = {}
  keys(params).map(key => {
    let value = params[key]
    if (!isNil(value)) {
      if (isPlainObject(value)) {
        keys(value).map(childKey => {
          parsedParams[`${key}[${childKey}]`] = value[childKey]
        })
      } else {
        parsedParams[key] = value
      }
    }
  })
  return parsedParams
}

export default parseParams
